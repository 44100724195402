import Vue from 'vue'

import '@/plugins/vant'
import '@vant/touch-emulator'
import 'normalize.css'
import '@/css/common.less'


// 1.禁用右键菜单
document.oncontextmenu = new Function("event.returnValue=false");
// 2.禁用鼠标选中
document.onselectstart = new Function("event.returnValue=false");
// 3.禁止键盘F12键
document.addEventListener("keydown", function (e) {
  if (e.key == "F12") {
    e.preventDefault(); // 如果按下键F12,阻止事件
  }
});