<template>
  <div ref="button" class="button">
    <slot />
  </div>
</template>

<script>
import cancelBg from '../../assets/img/cancel.png'
import cancelDown from '../../assets/img/cancel-down.png'
import cancelOver from '../../assets/img/cancel-over.png'
export default {
  mounted() {
    this.$refs.button.addEventListener('mousedown', () => {
      this.$refs.button.style.backgroundImage = `url(${cancelDown})`;
    });
    this.$refs.button.addEventListener('mouseover', () => {
      this.$refs.button.style.backgroundImage = `url(${cancelOver})`;
    });
    this.$refs.button.addEventListener('mouseleave', () => {
      this.$refs.button.style.backgroundImage = `url(${cancelBg})`;
    });
    this.$refs.button.addEventListener('mouseup', () => {
      this.$refs.button.style.backgroundImage = `url(${cancelBg})`;
    });
  },
}
</script>

<style lang="less" scoped>
.button {
  width: 106px;
  height: 32px;
  line-height: 32px;
  background-image: url("../../assets/img/cancel.png");
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
</style>