import Vue from 'vue';
import { Toast, Button } from 'vant';


Vue.use(Button);
// 将所有 Toast 的展示时长设置为 2000 毫秒
Toast.setDefaultOptions({ duration: 2000, forbidClick: true });

// 将所有 loading Toast 设置为背景不可点击
Toast.setDefaultOptions('loading', { forbidClick: true, duration: 0, loadingType: "spinner" });
Vue.use(Toast);
