import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/login/:platform',
    name: 'Login',
    component: () => import('../views/login')
  },
  {
    path: '/phone-login/:platform',
    name: 'PhoneLogin',
    component: () => import('../views/phone-login')
  },
  {
    path: '/register/:platform',
    name: 'Register',
    component: () => import('../views/register')
  },
  {
    path: '/forgot-password/:platform',
    name: 'ForgotPassword',
    component: () => import('../views/forgot-password')
  },
  {
    path: '/user-agreement',
    name: 'UserAgreement',
    component: () => import('../views/agreement')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
