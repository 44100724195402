<template>
  <div ref="button" class="button">
    <slot />
  </div>
</template>

<script>
import buttonBg from '../../assets/img/button.png'
import buttonDown from '../../assets/img/button-down.png'
import buttonOver from '../../assets/img/button-over.png'
export default {
  mounted() {
    this.$refs.button.addEventListener('mousedown', () => {
      this.$refs.button.style.backgroundImage = `url(${buttonDown})`;
      console.log('mousedown', this.$refs.button);
    });
    this.$refs.button.addEventListener('mouseover', () => {
      this.$refs.button.style.backgroundImage = `url(${buttonOver})`;
    });
    this.$refs.button.addEventListener('mouseleave', () => {
      this.$refs.button.style.backgroundImage = `url(${buttonBg})`;
    });
    this.$refs.button.addEventListener('mouseup', () => {
      this.$refs.button.style.backgroundImage = `url(${buttonBg})`;
    });
  },
}
</script>

<style lang="less" scoped>
.button {
  width: 106px;
  height: 32px;
  line-height: 32px;
  background-image: url("../../assets/img/button.png");
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
</style>