import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/plugins'


Vue.config.productionTip = false

import SubmitButton from "@/components/submit-button"
import CancelButton from "@/components/cancel-button"
Vue.component('SubmitButton', SubmitButton)
Vue.component('CancelButton', CancelButton)
new Vue({ router, store, render: h => h(App) }).$mount('#app')
